@import '../../../base/base';
.theme-logo {
  width: 62px;
  height: 62px;
}

body {
  &:before {
    display: none;
  }
}

body.error {
  color: #888ea8;
  height: 100%;
  font-size: 0.875rem;
  background: #fafafa;
  background-image: linear-gradient(338deg, #c39be3 0%, #f2eafa 76%);
  > .error-content {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 30px;
  }
  
}

.error {
  .mini-text {
    font-size: 33px;
    font-weight: 700;
    margin-bottom: 0;
    color: #060818;
  }

  .img-cartoon {
    width: 170px;
    height: 170px;
  }

  .error-img {
    max-width: 529px;
    margin-bottom: 50px;
    width: 100%;
    width: 363px;
  }

  .error-number {
    font-size: 170px;
    color: #060818;
    font-weight: 600;
    margin-bottom: 5px;
    margin-top: 15px;
    text-shadow: 0px 5px 4px rgba(31, 45, 61, 0.1019607843);
    display: none;
  }

  .error-text {
    font-size: 18px;
    color: $dark;
    font-weight: 600;
  }

  a.btn {
     width: 134px;
    padding: 6px;
    font-size: 17px;
    border: none;
    letter-spacing: 2px;
    box-shadow: none;
    display: block;
    margin: 0 auto;
  }
}